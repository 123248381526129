<template>
  <div class="common">
    <img
      src="@/assets/img/bg_problem.png"
      alt=""
      style="width: 100%"
      class="topI"
    />
    <div class="common-t">
      <div
        class="common-tt"
        style="display: flex; flex-direction: row; height: 70px"
      >
        <p style="margin-right: 10px; margin-left: -18px" class="wIBox">
          <img
            src="@/assets/img/warn.png"
            alt=""
            style="height: 40px"
            class="wI"
          />
        </p>
        <p style="margin-top: 30px; display: inline-block" class="cj">
          以下是常见问题答案
        </p>
      </div>
      <p class="common-tm">
        若无法解决您的问题，请<button
          type="button"
          class="el-button el-button--text"
          @click="contachMe"
        >
          <!----><!----><span>联系我们</span></button
        >，我们将为您服务。
      </p>
      <div style="text-align: left; margin-left: 30%" class="mainBox">
        <div style="margin-left: -10%" class="mainBoxItem">
          <p class="common-tml">1、我可以在不同设备登录使用巨石出海吗?</p>
          <p class="common-tmp" style="margin-top: 5px">
            是的，您可以在不同的电脑设备上登录使用巨石出海，登录账号之后，您的所有数据将同步到该台设备上。
          </p>
        </div>
        <div style="margin-left: -10%" class="mainBoxItem">
          <p class="common-tml">2、巨石出海安全吗?</p>
          <p class="common-tmp" style="margin-top: 5px">
            当然，巨石出海无法查看，也无法保存您的任何聊天数据，而且我们针对各个端口做了加密处理，用保护您的个人隐私。
          </p>
        </div>
        <div style="margin-left: -10%" class="mainBoxItem">
          <p class="common-tml">3、巨石出海支持哪些社交软件?</p>
          <p class="common-tmp" style="margin-top: 5px">
            巨石出海聚合了WhatsApp Telegram Facebook Twitter等多款海外热门社交软件，其他软件也将在后续的更新中不断添加进来。
          </p>
        </div>
        <div style="margin-left: -10%; margin-bottom: 50px" class="mainBoxItem">
          <p class="common-tml">4、翻译的语言准确吗?</p>
          <p class="common-tmp" style="margin-top: 5px">
            我们提供了专业的语言翻译功能，为了提高翻译的精准度,在使用过程中，请您尽量使用官方的，标准的表达方式,避免使用地方性、区域性的表达方式。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  async created() {},
  methods: {
    contachMe() {
      window.open("https://t.me/jushitw");
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.common {
  width: 100%;
  background-color: #fff;
}
.common .common-t {
  background: #fff;
  box-shadow: 5px 10px 20px 0 rgba(63, 140, 255, 0.1);
  border-radius: 28px;
  width: 50%;
  margin-left: 25%;
  margin-top: -125px;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.common .common-t .common-tt {
  font-size: 35px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222a41;
  line-height: 50px;
  text-align: left;
  margin-left: 15%;
}
.common .common-t .common-tm {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6d7795;
  line-height: 20px;
  text-align: left;
  margin-left: 18%;
}
.common .common-t .common-tml {
  margin-left: -10%;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #252d43;
  line-height: 32px;
  font-weight: 700;
  margin: 20px 0 10px -10%;
}
.common .common-t .common-tmp {
  font-size: 14px;
  width: 80%;
  margin-left: -4%;
  color: #6d7795;
}
@media (max-width: 991px) {
  .common-t {
    box-shadow: none !important;
    width: 100% !important;
  }
  .topI {
    display: none !important;
  }
  .common-t {
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
  .cj {
    font-size: 21px;
    font-family: FZDHTJW--GB1-0, FZDHTJW--GB1;
    font-weight: 700;
    color: #252d43;
    line-height: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    margin: 0 !important;
    margin-left: 10px !important;
  }
  .wI {
    widows: 26px !important;
    height: 26px !important;
  }
  .wIBox {
    margin: 0 !important;
  }
  .common-tt {
    align-items: center;
    justify-content: flex-start !important;
    margin-left: 15px !important;
  }
  .common-tm {
    margin-left: 15px !important;
    margin-top: 0px !important;
    font-size: 14px !important;
  }
  .mainBox {
    margin-left: 0px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .mainBoxItem {
    margin-left: 0px !important;
  }
  .common .common-t .common-tml {
    margin-left: 0px !important;
  }
  .common .common-t .common-tmp {
    margin-left: 0px !important;
  }
  .mainBoxItem {
    width: 82% !important;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(168, 225, 255, 0.5);
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px !important;
  }
  .common-t {
    position: relative !important;
    border-radius: 0px !important;
  }
  .common {
    height: auto !important;
  }
}
</style>