var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigScreen"},[_c('div',{staticClass:"down",attrs:{"data-v-adff3d9a":""}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._m(0),_c('img',{staticClass:"downimg",attrs:{"src":require("@/assets/img/download1.png")},on:{"click":_vm.downLoad1}}),_c('img',{staticClass:"downimg",attrs:{"src":require("@/assets/img/download2.png")},on:{"click":_vm.downLoad2}}),_c('div',{staticStyle:{"justify-content":"flex-start"}}),_vm._v(" 安装第二步：安装巨石客户端 "),_c('br'),_c('img',{staticClass:"downimg",attrs:{"src":require("@/assets/img/64down.png")},on:{"click":_vm.downZip}}),_c('div',{staticStyle:{"margin":"20px","color":"red","font-weight":"bold"}},[_vm._v(" 备注：由于每个端口都是模拟单独电脑，会需要大量cpu和内存，优先推荐【I5-十代及以上，内存32G】电脑配置 ")])]),_c('div',{staticClass:"block",staticStyle:{"margin-bottom":"20px"}},[_c('span',{staticClass:"demonstration"}),_c('el-carousel',{attrs:{"height":_vm.screenWidth < 992
            ? _vm.screenWidth < 450
              ? '100px'
              : '175px'
            : '636px'}},_vm._l((_vm.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{staticClass:"lbImg",style:({
              height:
                _vm.screenWidth < 992
                  ? _vm.screenWidth < 450
                    ? '100px'
                    : '175px'
                  : '636px',
              width: _vm.screenWidth < 992 ? 'auto' : '80%',
            }),attrs:{"src":item.url}})])}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"20px","margin-left":"30px","margin-right":"30px","display":"flex","justify-content":"center","text-align":"left"}},[_vm._v(" 安装第一步：安装运行环境 VC6 和 .NET Framework 4.6.2 "),_c('br')])
}]

export { render, staticRenderFns }