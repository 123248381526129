<template>
  <div class="bigScreen">
    <div data-v-adff3d9a="" class="down">
      <div style="cursor: pointer">
        <div
          style="
          margin-top: 20px;
          margin-left: 30px;
          margin-right: 30px;
          display: flex;
          justify-content: center;
          text-align: left;
          "
        >
          安装第一步：安装运行环境 VC6 和 .NET Framework 4.6.2

          <br />
        </div>

        <img
            src="@/assets/img/download1.png"
            class="downimg"
            @click="downLoad1"
        />
        <img
            src="@/assets/img/download2.png"
            class="downimg"
            @click="downLoad2"
        />
        <div style="justify-content: flex-start"></div>

        安装第二步：安装巨石客户端
        <br/>
        <img src="@/assets/img/64down.png" class="downimg" @click="downZip" />
        <div style="margin: 20px; color: red; font-weight: bold">
          备注：由于每个端口都是模拟单独电脑，会需要大量cpu和内存，优先推荐【I5-十代及以上，内存32G】电脑配置
        </div>
      </div>
      <div class="block" style="margin-bottom: 20px">
        <span class="demonstration"></span>
        <el-carousel
          :height="
            screenWidth < 992
              ? screenWidth < 450
                ? '100px'
                : '175px'
              : '636px'
          ">
          <el-carousel-item v-for="(item, index) in list" :key="index">
            <img
              :src="item.url"
              :style="{
                height:
                  screenWidth < 992
                    ? screenWidth < 450
                      ? '100px'
                      : '175px'
                    : '636px',
                width: screenWidth < 992 ? 'auto' : '80%',
              }"
              class="lbImg"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {dataFilling} from "@/api/main/admin";

export default {
  data() {
    return {
      list: [
        {
          url: require("@/assets/img/bg_pc_1.png"),
        },
        {
          url: require("@/assets/img/bg_h5_1.png"),
        },
      ],
      screenWidth: "",
      vc6path:'',
      netpath:'',
      win64path:''
    };
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      console.log("高度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.init();
      })();
    };
  },
  async created() {
    await this.dataFilling();
  },
  methods: {
    //VC6环境下载包
    downLoad1() {
      window.open(
          // "https://haibao-file.oss-ap-southeast-1.aliyuncs.com/VC_redist.x64.exe"
          this.vc6path
      );
    },
    //.Net环境下载包
    downLoad2() {
      window.open(
          // "https://dotnet.microsoft.com/zh-cn/download/dotnet-framework/thank-you/net462-web-installer"
          this.netpath
      );
    },
    //废弃
    downEnvironment() {
      window.open(
        "https://haibao-file.oss-ap-southeast-1.aliyuncs.com/VC_redist.x64.exe"
      );
    },
    //巨石安装包下载
    downZip() {
      window.open(
          // "https://jushiscrm.oss-ap-southeast-1.aliyuncs.com/%E5%B7%A8%E7%9F%B3%E5%87%BA%E6%B5%B70318-187.exe"
          this.win64path
      );
    },
    async dataFilling(){
      console.log('filling data...')
      const datas = await dataFilling();
      console.log(datas)
      this.vc6path=datas.vc6Url;
      this.netpath=datas.netUrl;
      this.win64path=datas.win64Url;
      console.log('done!')
    },
    init() {
      if (this.screenWidth <= 992) {
        this.list = [
          {
            url: require("@/assets/img/bg_pc.png"),
          },
          {
            url: require("@/assets/img/bg_h5.png"),
          },
        ];
      } else {
        this.list = [
          {
            url: require("@/assets/img/bg_pc_1.png"),
          },
          {
            url: require("@/assets/img/bg_h5_1.png"),
          },
        ];
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.bigScreen {
  flex: 1;
  color: #333;
  text-align: center;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
}
.down {
  width: 100%;
  height: 100%;
  background-image: url(@/assets/img/bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //padding-bottom: 100px;
}
.downimg {
  margin-top: 0px;
}
@media (max-width: 991px) {
  .down {
    height: 100%;
    padding-top: 80px;
  }
  .downimg {
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .bigScreen {
    height: calc(100% - 120px);
  }
}
.lbImg {
  object-fit: contain;
}

@media (max-width: 450px) {
  .downimg {
    width: 100px;
    height: 60px;
  }
}
</style>
