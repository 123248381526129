<template>
  <div class="main">
    <div class="imageClass">
      <img src="@/assets/img/logo_len.png" />
    </div>
    <div class="mainTitle">版权信息：巨石出海出品程序，拥有自主知识产权，盗版必究。</div>
  </div>
</template>       
<script>
export default {
  data() {
    return {
      tabList: [
        {
          name: "首页",
          imageUrl: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  display: flex;
  background: rgba(23, 42, 58);
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  .mainTitle {
    margin-left: 1.2em;
    font-size: 10pt;
    color: #fff;
    margin-top: 5px;
    margin-left: 10%;
  }
  .qQun {
    margin-left: 1em;
    font-size: 10pt;
    color: red;
  }
  .routerBox {
    display: flex;
    margin-left: 0.9375rem;
    .routerBoxItem {
      color: #10a55a !important;
      border-top: 1px solid #fff;
      border-bottom: 1px solid currentColor;
      padding: 1em 0;
      font-size: 14px;
      align-items: center;
      display: flex;
      margin-left: 15px;
      margin-right: 15px;
      .routerBoxItemImg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
}
@media (max-width: 991px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
  .mainTitle {
    margin-left: 0px !important;
  }
}
</style>