<template>
  <div class="main">
    <div class="imageClass">
      <img src="@/assets/img/logo.png"
           @click="gotoHomePage()"/>
    </div>
    <div class="routerBox">
      <div
        v-for="(item, index) in tabList"
        :key="item.pathName"
        :class="{
          routerBoxItem: item.pathName === $route.name,
          routerBoxItemUnClick: item.pathName !== $route.name,
          routerLogin: item.pathName == 'login',
        }"
        @click="gotoPage(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabList: [
        {
          name: "首页",
          pathName: "home",
        },
        {
          name: "下载",
          pathName: "download",
        },
        {
          name: "价格",
          pathName: "price",
        },
        {
          name: "常见问题",
          pathName: "problem",
        },
        {
          name: "免责声明",
          pathName: "mzsm",
        },
        {
          name: "登录",
          pathName: "login",
        },
      ],
    };
  },
  methods: {
    gotoHomePage() {
      this.$router.push({
        name: "home",
      });
    },
    gotoPage(item, index) {
      if (item.pathName == "login") {
        window.open("http://admin.jushi.tw/");
        return;
      }
      if (this.$route.name == item.pathName) {
        return;
      }
      console.log(this.$route);
      this.$router.push({
        name: item.pathName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  display: flex;
  justify-content: left;
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  .imageClass {
    margin-left: 20.66667%;
  }
  .mainTitle {
    color: #495057;
    font-size: 2rem;
    margin-left: 1.2em;
  }
  .qQun {
    margin-left: 1em;
    font-size: 10pt;
    color: red;
  }
}
.routerBox {
  display: flex;
  margin-left: 14.66667%;
  margin-right: 13%;
}
.routerBoxItem {
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #303133;
  border-top: 1px solid #fff;
  border-bottom: 4px solid #409eff;
  padding: 1em 0;
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}
.routerBoxItemUnClick {
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  border-bottom: 4px solid #fff;
  border-top: 1px solid #fff;
  padding: 1em 0;
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  color: rgb(109, 119, 149);
}

@media (max-width: 991px) {
  .routerLogin {
    display: none;
  }
  .main {
    flex-direction: column;
    padding-bottom: 0px;
  }
  .routerBox {
    margin-left: 0;
    margin-right: 0;
  }
  .routerBoxItem {
    border-bottom: none;
    background-color: #3f8cff;
    color: #fff;
    font-size: 15px;
    padding: 0 15px;
    border-radius: 3px;
    margin-left: 0px;
    margin-right: 0px;
    height: 40px;
  }
  .routerBoxItemUnClick {
    padding: 0 15px;
    margin-left: 0px;
    margin-right: 0px;
    height: 40px;
  }
  .imageClass {
    margin-left: 0 !important;
  }
}
</style>
